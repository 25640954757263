#my-signin2 {
    .abcRioButtonBlue {
        background-color: #4285f4;
        border: none;
        color: #fff;
    }

    .abcRioButton {
        border-radius: 1px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
        transition: background-color .218s, border-color .218s, box-shadow .218s;
        -webkit-user-select: none;
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        color: #262626;
        cursor: pointer;
        outline: none;
        overflow: hidden;
        position: relative;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        width: auto;
    }

    .abcRioButtonBlue .abcRioButtonContentWrapper {
        border: 1px solid transparent;
    }

    .abcRioButtonContentWrapper {
        height: 100%;
        width: 100%;
    }

    .abcRioButtonBlue .abcRioButtonIcon {
        background-color: #fff;
        border-radius: 1px;
    }

    .abcRioButtonIcon {
        float: left;
    }
}